import axios from "axios"
import { AuthLoginResponse, Profile, RefreshAccessTokenRequest } from "./models/AuthModels"
import { API_ENDPOINT_BASE } from "./config/AppConfig"
import { getFromLocalStorage, saveToLocalStorage } from "./utils/LocalStorageUtils";
import { RoundCollection } from "./models/GameModels";

const api = axios.create({
    baseURL: `${API_ENDPOINT_BASE}/api`,
});

// Add a request interceptor
api.interceptors.request.use(
    (config) => {
        const authData = getFromLocalStorage<AuthLoginResponse>('authData');
        if (authData) {
            config.headers.Authorization = `Bearer ${authData.accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const authData = getFromLocalStorage<AuthLoginResponse>('authData');

                if (!authData) {
                    // TODO relogin with telegram login data
                    return Promise.reject(error);
                }

                const refreshRequest: RefreshAccessTokenRequest = {
                    accessToken: authData.accessToken,
                    refreshToken: authData.refreshToken,
                };

                const response = await axios.post<AuthLoginResponse>(
                    `${API_ENDPOINT_BASE}/api/auth/refresh-access-token`, 
                    refreshRequest);

                saveToLocalStorage('authData', response.data);

                // Retry the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
                return axios(originalRequest);
            } catch (error) {
                // Handle refresh token error or redirect to login
            }
        }

        return Promise.reject(error);
    }
);


const getAuthToken = async (telegramInitData: string): Promise<AuthLoginResponse> => {
    const response = await axios.post<AuthLoginResponse>(
        `${API_ENDPOINT_BASE}/api/auth/login`,
        { telegramInitData: telegramInitData },
        {
            headers: { "Content-Type": "application/json" }
        });

    return response.data;
}

export const authLogin = async (telegramInitData: string): Promise<AuthLoginResponse> => {
    let authData = getFromLocalStorage<AuthLoginResponse>('authData');

    if (!authData) {
        authData = await getAuthToken(telegramInitData);
    }

    saveToLocalStorage('authData', authData);

    return authData;
}

export const getProfile = async (): Promise<Profile | undefined> => {
    try {
        const response = await api.get<Profile>('/profile');

        return response.data;

    } catch (error) {
        // Handle error or redirect to login
    }
}

export const getGameState = async (): Promise<RoundCollection | undefined> => {
    try {
        const response = await api.get<RoundCollection>('/rounds/current-state');

        return response.data;

    } catch (error) {
        // Handle error or redirect to login
    }
}