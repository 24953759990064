import { FC } from "react";

const MyGames: FC = () => {
    return (
        <div>
            My Games
        </div>
    )
}

export default MyGames;