import { FC, useEffect, useState } from "react";
import { RoundModel, RoundStatus } from "../../models/GameModels";
import classes from './RoundCard.module.scss';
import { useGameContext } from "../../contexts/GameContext";

export const RoundCard: FC<{ round: RoundModel }> = ({ round }) => {
    const { state } = useGameContext();

    const [priceDiff, setPriceDiff] = useState(round.status == RoundStatus.Finished ? round.priceDiff?.toFixed(2) : null);
    const [lastPrice, setLastPrice] = useState(round.status == RoundStatus.Finished ? round.closedPrice?.toFixed(2) : null);

    const priceChangeSymbol = priceDiff && +priceDiff > 0 ? '↑' : '↓';

    useEffect(() => {
        if (round.status != RoundStatus.Live) {
            return;
        }

        const diff = state.btcPrice - round.lockedPrice!;
        setPriceDiff(diff.toFixed(2));
        setLastPrice((+state.btcPrice).toFixed(2));

    }, [state.btcPrice])

    return (
        <div className={classes.Round}>
            <div
                className={classes.FinnishedRound}
                style={{ display: round.status == RoundStatus.Finished ? 'block' : 'none' }}></div>
            <div className={classes.Header}>
                {round.status} <span className={classes.Id}>#{round.roundId}</span>
            </div>
            <div className={classes.RoundInfoContainer}>
                <div className={classes.ArrowContainer}>
                    <div className={classes.Up} style={{ borderBottomColor: priceDiff && +priceDiff > 0 ? '#24cfaa' : '#353547' }}>
                    </div>
                    <div className={classes.UpText}>
                        UP {round.roundStats?.bullPayout?.toFixed(2)}
                    </div>
                </div>
                <div className={classes.RoundPriceInfo}>
                    <div className={classes.ClosePriceContainer}>
                        <div className={classes.Label}>
                            {round.status == RoundStatus.Live ? 'LAST PRICE' : 'CLOSED PRICE'}
                        </div>
                        <div className={classes.Value}>
                            <div>
                                {lastPrice}$
                            </div>
                            <div className={`${classes.Diff}`}>
                                {priceChangeSymbol} {priceDiff}$
                            </div>
                        </div>
                    </div>
                    <div className={classes.LockedPriceContainer}>
                        <div>
                            Locked Price
                        </div>
                        <div>
                            {round.lockedPrice}$
                        </div>
                    </div>
                    <div className={classes.LockedPriceContainer}>
                        <div>
                            Price Pool
                        </div>
                        <div>
                            {round.roundStats?.pricePool?.toFixed(2)} BTT
                        </div>
                    </div>
                </div>
                <div className={classes.ArrowContainer}>
                    <div className={classes.DownText}>
                        DOWN {round.roundStats?.bearPayout?.toFixed(2)}
                    </div>
                    <div className={classes.Down} style={{ borderTopColor: priceDiff && +priceDiff < 0 ? '#ff495c' : '#353547' }}>
                    </div>
                </div>
            </div>
        </div>
    )
}