import { FC } from "react";
import { useTelegram } from "../../hooks/useTelegram";
import classes from './LoginData.module.scss';
import { useAuthContext } from "../../contexts/AuthContext";

const LoginData: FC = () => {
    const { tg, initData, user } = useTelegram();
    const jwtToken = useAuthContext();


    return <div className={classes.Home}>
        <p>v3</p>
        <div className={classes.Border}>
            {
                user?.username ?
                    <div>
                        <p>{user.username}</p>
                        <p>tg init data: {tg.initData}</p>
                    </div>
                    : <div>
                        <p>Tg data not initialized</p>
                        <p>Using sample user with init data:</p>
                        <p>{initData}</p>
                    </div>
            }
        </div>
        <br />
        <div className={classes.Border}>
            {jwtToken == undefined ?
                <div>Failed to get auth token</div> :
                <div>
                    <p>
                        Jwt:
                    </p>
                    <p>
                        {jwtToken.accessToken}
                    </p>
                </div>}
        </div>
    </div>
}

export default LoginData;