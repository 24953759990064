import { createContext, useContext } from 'react';
import { RoundModel } from '../models/GameModels';

export interface GameStateModel {
    gameRounds: Array<RoundModel>;
    prematchRoundId: number;
    btcPrice: number;
}

export interface GameComtextModel {
    state: GameStateModel;
    dispatch: any | null;
}

const GameContext = createContext<GameComtextModel>(
    {
        state: {
            gameRounds: [],
            prematchRoundId: -1,
            btcPrice: 0,
        },
        dispatch: undefined
    });

export const useGameContext = () => useContext(GameContext);

export default GameContext;