import { FC } from "react";

const Leaderboard: FC = () => {
    return (
        <div>
            Leaderboard
        </div>
    )
}

export default Leaderboard;