export interface RoundModel {
    roundId: number;
    lockedPrice: number | null;
    closedPrice: number | null;
    status: RoundStatus;
    createdAt: Date;
    lockedAt: Date | null;
    closedAt: Date | null;
    estimatedLockedDate: Date;
    estimatedClosedAt: Date;
    durationSeconds: number;
    lockBeforeEndSeconds: number;
    isRoundValid: boolean;
    roundResult: RoundResult;
    roundStats: RoundStats;
    priceDiff?: number;
}

export interface RoundStats {
    totalBetBullAmount: number;
    totalBetBearAmount: number;
    totalBetBull: number;
    totalBetBear: number;
    bullPayout: number;
    bearPayout: number;
    pricePool: number;
}

export interface RoundCollection {
    prematchRoundId: number;
    rounds: RoundModel[];
}

export enum RoundStatus {
    Prematch = 'Prematch',
    Live = 'Live',
    Finished = 'Finished',
    Failed = 'Failed',
}

export enum RoundResult {
    Unknown = 'Unknown',
    BullWin = 'BullWin',
    BearWin = 'BearWin',
    Draw = 'Draw',
}