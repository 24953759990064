import { FC, useEffect, useRef } from "react";
import { useGameContext } from "../../contexts/GameContext";
import classes from './Home.module.scss';
import { RoundCard } from "../RoundCard";

const Home: FC = () => {
    const { state } = useGameContext();

    const containerRef = useRef<HTMLDivElement>(null);
    const itemToCenterRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (itemToCenterRef.current && containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const itemRect = itemToCenterRef.current.getBoundingClientRect();

            // Calculate the scroll position needed to center the item
            const scrollLeft = itemToCenterRef.current.offsetLeft - containerRect.width / 2 + itemRect.width / 2;

            containerRef.current.scroll({ left: scrollLeft, behavior: 'smooth' });
        }
    }, [state.gameRounds]);

    return (
        <div className={classes.Home}>
            <div>
                <div>prematch round id: {state.prematchRoundId}</div>
            </div>
            <div className={classes.RoundsContainer} ref={containerRef}>
                {state.gameRounds.map((round, index) => {
                    return (
                        <div className={classes.Round} key={round.roundId} ref={index === state.gameRounds.length - 2 ? itemToCenterRef : null}>
                            <RoundCard round={round}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Home;
