import { useEffect, useReducer, useState } from 'react';
import logo from './logo.svg';
import classes from './App.module.scss';
import { useTelegram } from './hooks/useTelegram';
import { NavLink, Route, Routes } from 'react-router-dom';
import LoginData from './components/LoginData';
import AuthContext from './contexts/AuthContext';
import { AuthLoginResponse, Profile } from './models/AuthModels';
import { Centrifuge, PublicationContext, SubscribedContext, SubscriptionState, SubscriptionStateContext } from 'centrifuge';
import { WS_ENDPOINT } from './config/AppConfig';
import Home from './components/Home';
import MyGames from './components/MyGames';
import Leaderboard from './components/Leaderboard';
import { authLogin, getGameState, getProfile } from './AppApi';
import { reducer } from './utils/ReducesUtils';
import GameContext, { GameComtextModel, GameStateModel } from './contexts/GameContext';

const initState: GameStateModel = {
  prematchRoundId: -1,
  gameRounds: [],
  btcPrice: 0,
};

function App() {
  const { tg, onClose, initData } = useTelegram();
  const [loading, setLoading] = useState<boolean>(true);
  const [authData, setAuthData] = useState<AuthLoginResponse>();
  const [profile, setProfile] = useState<Profile>();
  const [realTimeStatus, setRealTimeStatus] = useState('🔴');
  const [btcPrice, setBtcPrice] = useState<any>();
  const [gameState, dispatch] = useReducer(reducer, initState);


  useEffect(() => {
    tg.ready();
    tg.expand();

    return () => {
      onClose();
    }
  }, []);

  useEffect(() => {
    login();
  }, [initData]);

  useEffect(() => {
    if (!authData) {
      return;
    }

    let centrifuge: Centrifuge | null = null;

    const init = async () => {
      console.log("new Centrifuge")

      const gameRounds = await getGameState();
      
      dispatch({
        type: 'INIT_ROUNDS', payload: {
          'rounds': gameRounds
        }
      })

      centrifuge = new Centrifuge(WS_ENDPOINT, {
        token: authData.accessToken,
        // debug: true
      })

      const sub = centrifuge.newSubscription("btc-feed");
      const sub2 = centrifuge.newSubscription("round-updates");
      
      sub.on('publication', (ctx: PublicationContext) => {
        dispatch({
          type: 'UPDATE_BTC_PRICE', payload: {
            'price': ctx.data.Price
          }
        })
        setBtcPrice(ctx.data);
      }).on('subscribed', (ctx: SubscribedContext) => {
        if (ctx.wasRecovering && !ctx.recovered) {
          // TODO recover
          console.log('State LOST - please reload the page')
        }
      })

      sub2.on('publication', (ctx: PublicationContext) => {
        // dispatch({
        //   type: 'UPDATE_ROUNDS', payload: ctx.data
        // })
        console.log(ctx.data)
      }).on('subscribed', (ctx: SubscribedContext) => {
        if (ctx.wasRecovering && !ctx.recovered) {
          // TODO recover
          console.log('State LOST - please reload the page')
        }
      })

      sub.on('state', (ctx: SubscriptionStateContext) => {
        if (ctx.newState == SubscriptionState.Subscribed) {
          setRealTimeStatus('🟢')
        } else {
          setRealTimeStatus('🔴')
        }
      })

      sub.subscribe()
      sub2.subscribe()

      centrifuge.on('connecting', function (ctx) {
        console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
      }).on('connected', function (ctx) {
        console.log(`connected over ${ctx.transport}`);
      }).on('disconnected', function (ctx) {
        console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
      }).connect();
    }

    init()

    return () => {
      if (centrifuge) {
        
        console.log("disconnect Centrifuge")
        centrifuge.disconnect()
      }
    }

  }, [authData]);

  const login = async () => {
    if (!initData) {
      console.log("no init data")
      return;
    }

    try {
      setLoading(true);

      const authResponse = await authLogin(initData);

      const profile = await getProfile();

      setAuthData(authResponse);
      setProfile(profile);
    } catch (err) {
      console.error('Login failed:', err);
      // TODO: handle login errors.
    } finally {
      setLoading(false);
    }
  }

  return (
    loading
      ?
      <div>loading...</div>
      :
      <AuthContext.Provider value={authData}>
        <GameContext.Provider value={{ state: gameState, dispatch }}>
          <div className={classes.App}>
            <div className={classes.Header}>
              <div className={classes.LinksAndBalance}>
                <div>
                  <NavLink to="/">Home</NavLink>
                  <NavLink to="/my-games">My Games</NavLink>
                  <NavLink to="/leaderboard">Leaderboard</NavLink>
                  <NavLink to="/login-data">Login Data</NavLink>
                </div>
                <div>
                  Balance: {profile?.balance}
                </div>
              </div>
              <div className={classes.BtcContainer}>
                <div>
                  BTC: {`${(+btcPrice?.Price).toFixed(2)}$` ?? "...."}
                </div>
                <div>
                  status: {realTimeStatus}
                </div>
              </div>
            </div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/my-games" element={<MyGames />} />
              <Route path="/login-data" element={<LoginData />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
            </Routes>
          </div>
        </GameContext.Provider>
      </AuthContext.Provider>
  );
}

export default App;
