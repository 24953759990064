import { GameStateModel } from "../contexts/GameContext";
import { RoundCollection, RoundModel, RoundStats } from "../models/GameModels";

export function reducer(state: GameStateModel, action: any): GameStateModel {
    switch (action.type) {
        case 'INIT_ROUNDS': {
            const rounds = action.payload.rounds as RoundCollection;

            if (!rounds) {
                console.log("invalid rounds dispached");
                return state;
            }

            return {
                ...state,
                prematchRoundId: rounds.prematchRoundId,
                gameRounds: rounds.rounds
            };
        }
        case 'UPDATE_BTC_PRICE': {
            const price = action.payload.price as number;

            if (!price) {
                console.log("invalid price dispached");
                return state;
            }

            return {
                ...state,
                btcPrice: price,
            };
        }
        case 'UPDATE_ROUNDS': {
            const prematchId = +action.payload.newPrematchId;
            const newPrematchRound = action.payload.newPrematchRound as RoundModel;
            const roundUpdates = action.payload.roundUpdates;

            var rounds = state.gameRounds.map(x => {
                return roundUpdates[x.roundId] ?? x;
            });
            
            if(newPrematchRound){
                rounds.push(newPrematchRound);
            }

            return {
                ...state,
                prematchRoundId: prematchId,
                gameRounds: rounds,
            }
        }
        default:
            return state;
    }
}