import { useEffect, useState } from 'react';
import { TelegramWebApps } from 'telegram-webapps-types-new';
import { LOCAL_TG_INIT_SAMPLE } from '../config/AppConfig';

const tg = (window as any).Telegram.WebApp as TelegramWebApps.WebApp;

export const useTelegram = () => {
    const [initData, setInitData] = useState<string>(tg.initData);

    useEffect(() => {
        if (!tg.initData) {
            console.log("no initData from telegram")
            setInitData(LOCAL_TG_INIT_SAMPLE);
        } else {
            setInitData(tg.initData);
        }

    }, [tg]);

    const onClose = () => {
        tg.close();
    }

    return {
        tg,
        onClose,
        user: tg.initDataUnsafe.user,
        initData,
    }
}