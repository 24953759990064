export function saveToLocalStorage<T>(key: string, value: T): void {
    const serializedData = JSON.stringify(value);

    localStorage.setItem(key, serializedData);
};

export function getFromLocalStorage<T>(key: string): T | null {
    const serializedData = localStorage.getItem(key);
    return serializedData ? JSON.parse(serializedData) as T : null;
}
